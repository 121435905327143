import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';


// Get all Tenants
export const getTenants = createAsyncThunk('tenant/getTenants', async (_, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.get(`/v1/admin/tenant`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

// Add Tenant
export const addTenant = createAsyncThunk('tenant/addTenant', async (newTenant, {rejectWithValue}) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const res = await axiosInstance.post('/v1/admin/tenant', newTenant, config)
    const tenant = res.data
    return { tenant }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

// Register Admin User
export const registerAdmin = createAsyncThunk('tenant/registerAdmin', async ({formData, tenant}, {rejectWithValue, dispatch}) => {
  
    const config = {
      headers: {
        tenant: tenant,
        'Content-Type': 'application/json'
      }
    }

    try {
      const res = await axiosInstance.post('/v1/tenant/user/signup', formData, config)
      return res.data

    } catch (error) {
      setTimeout(() => {
        dispatch({
          type: 'tenant/clearErrors'
        });
      }, 4000);
      return rejectWithValue(
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg : error.response.data.error ? error.response.data.error
            : 'An error occurred'
      );
    }
})

export const uploadLogo = createAsyncThunk('tenant/uploadLogo', async (data, {rejectWithValue}) =>{
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    const res = await axiosInstance.post('/v1/tenant/logo', data, config)
    return res.data.updated
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const uploadCampaignData = createAsyncThunk('tenant/uploadCampaignData', async (data, {rejectWithValue}) => {
  try {
    const config = {
      headers: {
        'Content-Type':   data.has('banner1') || data.has('banner2') ? 'multipart/form-data' : 'application/json'
      }
    }
    const res = await axiosInstance.post('/v1/tenant/campaignData', data, config)
    return res.data.updated
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
}) 

export const getLogo = createAsyncThunk('tenant/getLogo', async (_, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.get('/v1/tenant/logo')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getCampaignData = createAsyncThunk('tenant/getCampaignData', async (_, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.get('/v1/tenant/campaignData')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveSignature = createAsyncThunk('tenant/saveSignature', async (data, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.post('/v1/tenant/signature', data)
    return res.data
  } catch (error) {
      return rejectWithValue(error.response ? error.response.data.msg : 'An error occurred');
  }
})

export const getSignature = createAsyncThunk('tenant/getSignature', async (_, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.get('/v1/tenant/signature')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const uploadCompanyName = createAsyncThunk('tenant/uploadCompanyName', async (data, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.put('/v1/tenant/companyName', data)
    return res.data.updated
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const uploadCompanyWebsite = createAsyncThunk('tenant/uploadCompanyWebsite', async (data, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.put('/v1/tenant/companyWebsite', data)
    return res.data.updated
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateTicketCreationMessage = createAsyncThunk('tenant/updateTicketCreationMessage', async (data, {rejectWithValue}) => {
  try {
    const res = await axiosInstance.put('/v1/tenant/ticketMessage', data)
    return res.data.updated
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getCustomTags = createAsyncThunk('tenant/getCustomTags', async (_, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.get('/v1/tenant/customTags')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateCustomTags = createAsyncThunk('tenant/updateCustomTags', async (data, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.put('/v1/tenant/customTags',data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getAccountLevelConfigurations = createAsyncThunk('tenant/getAccountLevelConfigurations', async (_, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.get('/v1/tenant/account-configurations')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveAccountLevelConfigurations = createAsyncThunk('tenant/saveAccountLevelConfigurations', async (data, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.put('/v1/tenant/account-configurations', data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const verifyEmailAddress = createAsyncThunk('tenant/verifyEmailAddress', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post(`/v1/tenant/verifyEmail`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getVerifiedEmailAddress = createAsyncThunk('tenant/getVerifiedEmailAddress', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/verifiedEmail`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const resendVerificationMail = createAsyncThunk('tenant/resendVerificationMail', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post(`/v1/tenant/resend-verification`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getEmailTemplates = createAsyncThunk('tenant/getEmailTemplates', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'x-host-name': window.location.hostname
      }
    }
    const res = await axiosInstance.get(`/v1/tenant/email-templates`,config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const duplicateEmailTemplate = createAsyncThunk('tenant/duplicateEmailTemplate', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.post(`/v1/tenant/duplicate-email-template`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const deleteEmailTemplate = createAsyncThunk('tenant/deleteEmailTemplate', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.delete(`/v1/tenant/delete-email-template/${data.templateId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveBanner = createAsyncThunk('tenant/saveBanner', async ({banner, isEdit, id}, { rejectWithValue }) => {
  try {
    const url = isEdit
      ? `/v1/tenant/banners/${id}`
      : `/v1/tenant/banners`;

    let res

    if(isEdit){
      res = await axiosInstance.put(url, banner)
    }else{
      res = await axiosInstance.post(url, banner)
    }
    
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBannerData = createAsyncThunk('tenant/getBannerData', async (_, { rejectWithValue }) => {
  try {

    const res = await axiosInstance.get(`/v1/tenant/bannerData`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const deleteBanner = createAsyncThunk('tenant/deleteBanner', async (data, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.delete(`/v1/tenant/banners/${data.id}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getNewSitesConfigurations = createAsyncThunk('tenant/getNewSitesConfigurations', async (_, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.get('/v1/tenant/new-sites-configurations')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveNewSitesConfigurations = createAsyncThunk('tenant/saveNewSitesConfigurations', async (data, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.put('/v1/tenant/new-sites-configurations', data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getAmcTicketDays = createAsyncThunk('tenant/getAmcTicketDays', async (_, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.get('/v1/tenant/amc-ticket-days')
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const updateAmcTicketDays = createAsyncThunk('tenant/updateAmcTicketDays', async (data, {rejectWithValue}) =>  {
  try {
    const res = await axiosInstance.put('/v1/tenant/amc-ticket-days',data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})
