// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const initialState = {};

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;


//converting redux to rtk

import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import userReducer from './features/user/userSlice'
import ticketReducer from './features/ticket/ticketSlice'
import commentReducer from './features/comment/commentSlice'
import tenantReducer from './features/tenant/tenantSlice'
import analyticsReducer from './features/analytics/analyticsSlice'
import siteReducer from './features/site/siteSlice'
import inverterReducer from './features/inverter/inverterSlice'
import themeReducer from './features/theme/themeSlice'
import formReducer from './features/forms/formSlice'
import integrationReducer from './features/integrations/integrationsSlice'
import helpReducer from './features/help/helpSlice'

import { setupAxiosInterceptors } from './utils/axiosInstance'

const defaultMiddlewareConfig = {
  // serializableCheck: {
  //   ignoredPaths: ["analytics.filterData.date", "filterData.date","analytic.filterData.date","payload.date","analytics.historicalStartDate","analytics.historicalEndDate","analytics.weeklyEnergyData","payload.date","ticket.active_date_filter","ticket.maintenanceReportData","ticket.maintenanceReportPreviewData"],
  // },
  immutableCheck: false,
  serializableCheck: false,
};

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    ticket: ticketReducer,
    comment: commentReducer,
    tenant: tenantReducer,
    analytics:analyticsReducer,
    site:siteReducer,
    inverter:inverterReducer,
    theme: themeReducer,
    form: formReducer,
    integration: integrationReducer,
    help: helpReducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware(defaultMiddlewareConfig),
})

setupAxiosInterceptors(store);

export default store


// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default () => {
//   let store = createStore(persistedReducer, rootReducer);
//   let persistor = persistStore(store);
//   return { store, persistor }
// }
