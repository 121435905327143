import { createSlice } from '@reduxjs/toolkit'
import {
  addTenant,
  getCampaignData,
  getLogo,
  getSignature,
  getTenants,
  registerAdmin,
  saveSignature,
  updateTicketCreationMessage,
  uploadCampaignData,
  uploadCompanyName,
  uploadCompanyWebsite,
  uploadLogo,
  getCustomTags,
  updateCustomTags,
  getVerifiedEmailAddress
} from './tenantThunks'


const initialState = {
  tenant: null,
  tenants: null,
  error: null,
  logoUpdated: null,
  campaignDataUpdated: null,
  logoData: null,
  campaignData: null,
  logoLoading: false,
  campaignDataLoading: false,
  logoUploading: false,
  campaignDataUploading: false,
  signatureSaving: false,
  signatureSaved: null,
  signatureLoading: false,
  signatureData: null,
  companyNameUploading: false,
  companyNameUploaded: null,
  companyWebsiteUploading: false,
  companyWebsiteUploaded: null,
  ticketCreationMessageUploading: false,
  ticketCreationMessageUploaded: null,
  tenantUserRegistering: false,
  tenantUserRegisterStatus: null,
  tenantUserRegisterError:null,
  customTags:null,
  customTagsLoading:false,
  customTagsUpdated:null,
  customTagsUpdating:false,
  verifiedEmailAddress : null,
  emailAddressesLoading : false
}

const resetStateOnFailure = (state) => {
  state.logoLoading = false
  state.campaignDataLoading = false
  state.logoUploading = false
  state.campaignDataUploading = false
  state.signatureSaving = false
  state.signatureLoading = false
  state.companyNameUploading = false
  state.companyWebsiteUploading = false
  state.ticketCreationMessageUploading = false
  state.customTagsLoading = false
  state.emailAddressesLoading = false
};

const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    resetTenantState() {
      return initialState
    },
    clearErrors(state) {
      state.error = null
      state.tenantUserRegisterError = null
    },
    updateVerifiedEmailAddresses(state,action){
      state.verifiedEmailAddress = action.payload
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getTenants.fulfilled, (state, action) => {
        state.tenants = action.payload
      })
      .addCase(getTenants.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(addTenant.pending, (state, action) => {
        state.error = null
      })
      .addCase(addTenant.fulfilled, (state, action) => {
        state.tenant = action.payload
      })
      .addCase(addTenant.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(uploadLogo.pending, (state) => {
        state.logoUploading = true;
      })
      .addCase(uploadLogo.fulfilled, (state, action) => {
        state.logoUpdated = action.payload
        state.logoUploading = false
      })
      .addCase(uploadLogo.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(uploadCampaignData.pending, (state) => {
        state.campaignDataUploading = true;
      })
      .addCase(uploadCampaignData.fulfilled, (state, action) => {
        state.campaignDataUpdated = action.payload
        state.campaignDataUploading = false
      })
      .addCase(uploadCampaignData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getLogo.pending, (state) => {
        state.logoLoading = true;
      })
      .addCase(getLogo.fulfilled, (state, action) => {
        state.logoData = action.payload
        state.logoLoading = false
      })
      .addCase(getLogo.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getCampaignData.pending, (state) => {
        state.campaignDataLoading = true;
      })
      .addCase(getCampaignData.fulfilled, (state, action) => {
        state.campaignData = action.payload
        state.campaignDataLoading = false
      })
      .addCase(getCampaignData.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)

      })
      .addCase(saveSignature.pending, (state) => {
        state.signatureSaving = true
        state.signatureSaved = null
      })
      .addCase(saveSignature.fulfilled, (state, action) => {
        state.signatureSaving = false
        state.signatureSaved = action.payload
      })
      .addCase(saveSignature.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getSignature.pending, (state) => {
        state.signatureLoading = true
        state.signatureData = null
      })
      .addCase(getSignature.fulfilled, (state, action) => {
        state.signatureLoading = false
        state.signatureData = action.payload
      })
      .addCase(getSignature.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(uploadCompanyName.pending, (state) => {
        state.companyNameUploading = true
        state.companyNameUploaded = null
      })
      .addCase(uploadCompanyName.fulfilled, (state, action) => {
        state.companyNameUploading = false
        state.companyNameUploaded = action.payload
      })
      .addCase(uploadCompanyName.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(uploadCompanyWebsite.pending, (state) => {
        state.companyWebsiteUploading = true
        state.companyWebsiteUploaded = null
      })
      .addCase(uploadCompanyWebsite.fulfilled, (state, action) => {
        state.companyWebsiteUploading = false
        state.companyWebsiteUploaded = action.payload
      })
      .addCase(uploadCompanyWebsite.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateTicketCreationMessage.pending, (state) => {
        state.ticketCreationMessageUploading = true
        state.ticketCreationMessageUploaded = null
      })
      .addCase(updateTicketCreationMessage.fulfilled, (state, action) => {
        state.ticketCreationMessageUploading = false
        state.ticketCreationMessageUploaded = action.payload
      })
      .addCase(updateTicketCreationMessage.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(registerAdmin.pending, (state) => {
        state.tenantUserRegistering = true
        state.tenantUserRegisterError = null
        state.tenantUserRegisterStatus = null
        state.error = null
      })
      .addCase(registerAdmin.fulfilled, (state, action) => {
        state.tenantUserRegistering = false
        if(action.payload.userCreated === 'success' ){
          state.tenantUserRegisterError = null
          state.tenantUserRegisterStatus = 'success'
        }else{
          state.tenantUserRegisterError = 'Error while creating user'
          state.tenantUserRegisterStatus = 'failed'
        }
      })
      .addCase(registerAdmin.rejected, (state, action) => {
        state.tenantUserRegisterStatus = 'failed'
        state.tenantUserRegistering = false
        state.tenantUserRegisterError = action.payload
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getCustomTags.pending, (state) => {
        state.customTagsLoading = true;
        state.customTags = null
      })
      .addCase(getCustomTags.fulfilled, (state, action) => {
        state.customTags = action.payload
        state.customTagsLoading = false
      })
      .addCase(getCustomTags.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(updateCustomTags.pending, (state) => {
        state.customTagsUpdated = null
        state.customTagsUpdating = true
      })
      .addCase(updateCustomTags.fulfilled, (state, action) => {
        state.customTagsUpdated = action.payload
        state.customTagsUpdating = false
      })
      .addCase(updateCustomTags.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
      .addCase(getVerifiedEmailAddress.pending, (state) => {
        state.verifiedEmailAddress = null
        state.emailAddressesLoading = true
      })
      .addCase(getVerifiedEmailAddress.fulfilled, (state, action) => {
        state.emailAddressesLoading = false
        state.verifiedEmailAddress = action.payload
      })
      .addCase(getVerifiedEmailAddress.rejected, (state, action) => {
        state.error = action.payload
        resetStateOnFailure(state)
      })
  }
})

export const { resetTenantState, clearErrors, updateVerifiedEmailAddresses } = tenantSlice.actions
export default tenantSlice.reducer