import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {},
  extraReducers(builder) {
 }
})
export const {  } = helpSlice.actions

export default helpSlice.reducer;