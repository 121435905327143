import React, { useEffect, Suspense, useState } from 'react'
import { createBrowserRouter, Outlet } from 'react-router-dom'
// Components
import Navbar from './components/layout/Navbar'
import PrivateRoute from './components/routing/PrivateRoute'
// Redux
import { Provider } from 'react-redux'
import store from './store'
// Utils
import setAuthToken from './utils/setAuthToken'
// Styles
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css/dist/js/materialize.min.js'
import './App.css'
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faSpinner,
  faUser,
  faUserPlus,
  faColumns,
  faFileInvoice,
  faExclamationCircle,
  faSearch,
  faCog,
  faSignInAlt,
  faSignOutAlt,
  faBars,
  faFilter,
  faSort,
  faSortAmountUp,
  faSortAmountDown,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericDown,
  faSortNumericUp,
  faEye,
  faPlus,
  faEdit,
  faPencilAlt,
  faChevronLeft,
  faChevronRight,
  faChevronCircleDown,
  faPaperPlane,
  faCommentDots,
  faComments
} from '@fortawesome/free-solid-svg-icons'
import Skeleton from './components/layout/Skeleton'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import solyiedIcon from './images/solyieldIcon.ico'
import Navbars from './components/layout/Navbars'
import { Tooltip } from 'react-tooltip'
import lazyRetry from './utils/lazyRetry.js'
import ScrollToTop from './components/layout/ScrollToTop.jsx'

// icons library
library.add(
  faSpinner,
  faUser,
  faUserPlus,
  faColumns,
  faFileInvoice,
  faExclamationCircle,
  faSearch,
  faCog,
  faSignInAlt,
  faSignOutAlt,
  faBars,
  faFilter,
  faSort,
  faSortAmountUp,
  faSortAmountDown,
  faSortAlphaUp,
  faSortAlphaDown,
  faSortNumericDown,
  faSortNumericUp,
  faEye,
  faPlus,
  faEdit,
  faPencilAlt,
  faTrashAlt,
  faChevronLeft,
  faChevronRight,
  faChevronCircleDown,
  faPaperPlane,
  faCommentDots,
  faComments
)

if (localStorage.token && localStorage.tenant) {
  setAuthToken(localStorage.token, localStorage.tenant)
}

const Dashboard = React.lazy(() => lazyRetry(() => import('./components/dashboard/Dashboard')));
const TicketsPage = React.lazy(() => lazyRetry(() => import('./components/tickets/TicketsPage')));
const Ticket = React.lazy(() => lazyRetry(() => import('./components/tickets/Ticket')));
const Settings = React.lazy(() => lazyRetry(() => import('./components/settings/Settings')));
const Analytics = React.lazy(() => lazyRetry(() => import('./components/analytics/Analytics')));
const SitePage = React.lazy(() => lazyRetry(() => import('./components/site/SitePage')));
const SiteSettings = React.lazy(() => lazyRetry(() => import('./components/site/SiteSettings')));
const InverterSettings = React.lazy(() => lazyRetry(() => import('./components/inverter/InverterSettings')));
const ModuleFaultDetection = React.lazy(() => lazyRetry(() => import('./components/analytics/ModuleFaultDetection')));
const SitesSummary = React.lazy(() => lazyRetry(() => import('./components/analytics/SitesSummary')));
const GeoSpatialDashboard = React.lazy(() => lazyRetry(() => import('./components/geoSpatialDashboard/GeoSpatialDashboard')));
const AnalyticsTwo = React.lazy(() => lazyRetry(() => import('./components/analyticsTwo/AnalyticsTwo')));
const IndividualScreen = React.lazy(() => lazyRetry(() => import('./components/analyticsTwo/IndividualScreen')));
const TemplatesPage = React.lazy(() => lazyRetry(() => import('./components/templates/TemplatesPage')));
const SitePageTemplate = React.lazy(() => lazyRetry(() => import('./components/templates/SitePageTemplate')));
const CommLossEmail = React.lazy(() => lazyRetry(() => import('./components/templates/CommLossEmail')));
const MaintenanceReportTemplate = React.lazy(() => lazyRetry(() => import('./components/templates/MaintenanceReportTemplate')));
const CustomiseSitePage = React.lazy(() => lazyRetry(() => import('./components/site/SitePage/CustomiseSitePage')));
const UpdateWarranty = React.lazy(() => lazyRetry(() => import('./components/site/UpdateWarranty')));
const ViewWarranty = React.lazy(() => lazyRetry(()=> import('./components/site/ViewWarranty.jsx')))
const SystemInfo = React.lazy(() => lazyRetry(() => import('./components/customer/SystemInfo')));
const ServiceRequest = React.lazy(() => lazyRetry(() => import('./components/customer/ServiceRequest')));
const Login = React.lazy(() => lazyRetry(() => import('./components/auth/Login')));
const Signup = React.lazy(() => lazyRetry(() => import('./components/auth/Signup')));
const Error = React.lazy(() => lazyRetry(() => import('./components/Error/Error')));
const CreateTicket = React.lazy(() => lazyRetry(() => import('./components/tickets/CreateTicket.jsx')))
const CreateCustomerTicket = React.lazy(() => lazyRetry(() => import('./components/customer/CreateCustomerTicket.jsx')))
const AddSite = React.lazy(() => lazyRetry(() => import('./components/site/AddSite.jsx')))
const UpdateTicket = React.lazy(() => lazyRetry(() => import('./components/tickets/UpdateTicket.jsx')))
const Integrations = React.lazy(() => lazyRetry(() => import('./components/integrations/IntegrationsPage.jsx')))
const NewSites = React.lazy(() => lazyRetry(() => import('./components/analytics/NewSites.jsx')));

//lazy loading modals
const DeleteTicketModal = React.lazy(() => lazyRetry(() => import('./components/tickets/DeleteTicketModal')));
const AddCommentModal = React.lazy(() => lazyRetry(() => import('./components/comments/AddCommentModal')));
const DeleteCommentModal = React.lazy(() => lazyRetry(() => import('./components/comments/DeleteCommentModal')));
const FullViewModal = React.lazy(() => lazyRetry(() => import('./components/analytics/SummaryTable/FullViewModal')));
const SendMailModal = React.lazy(() => lazyRetry(() => import('./components/tickets/SendMailModal')));
const SummaryMailModal = React.lazy(() => lazyRetry(() => import('./components/analytics/SummaryTable/SummaryMailModal')));
// const ViewSiteModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/ViewSiteModal')));
// const EditSiteModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditSiteModal')));
const EditConfigModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditConfigModal')));
const AddConfigModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/AddConfigModal')));
const EditMaintenanceModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditMaintenanceModal')));
const DeleteConfigModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/DeleteConfigModal')));
// const ViewNewSiteModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/ViewNewSiteModal')));
// const EditNewSiteModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditNewSiteModal')));
const AddInverterModal = React.lazy(() => lazyRetry(() => import('./components/inverter/AddInverterModal')));
const UpdateInverterModal = React.lazy(() => lazyRetry(() => import('./components/inverter/UpdateInverterModal')));
const ViewInfoModal = React.lazy(() => lazyRetry(() => import('./components/settings/ViewInfoModal')));
const EditInfoModal = React.lazy(() => lazyRetry(() => import('./components/settings/EditInfoModal')));
const DeleteInfoModal = React.lazy(() => lazyRetry(() => import('./components/settings/DeleteInfoModal')));
const UploadImagesModal = React.lazy(() => lazyRetry(() => import('./components/tickets/UploadImagesModal')));
const ViewTicketModal = React.lazy(() => lazyRetry(() => import('./components/site/SitePage/ViewTicketModal')));
const ReportOptionsSelectionModal = React.lazy(() => lazyRetry(() => import('./components/site/SitePage/ReportOptionsSelectionModal')));
// const CommLossEmailPreview = React.lazy(() => lazyRetry(() => import('./components/templates/CommLossEmailPreview')));
const CommLossSchedulingModal = React.lazy(() => lazyRetry(() => import('./components/settings/CommLossSchedulingModal')));
const PreviewMaintenanceReportModal = React.lazy(() => lazyRetry(() => import('./components/tickets/PreviewMaintenanceReportModal')));
const MaintenanceReportPreviewModal = React.lazy(() => lazyRetry(() => import('./components/templates/MaintenanceReportPreviewModal')));
const SetSignatureModal = React.lazy(() => lazyRetry(() => import('./components/settings/SetSignatureModal')));
const UpdateCustomerDetailsModal = React.lazy(() => lazyRetry(() => import('./components/settings/UpdateCustomerDetailsModal')));
const AddUserDetailsModal = React.lazy(() => lazyRetry(() => import('./components/settings/AddUserDetailsModal')));
const EditStringConfigModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditStringConfigModal')));
const Banner = React.lazy(() => lazyRetry(() => import('./components/layout/Banner')));
const DescriptionPaneModal = React.lazy(() => lazyRetry(() => import('./components/layout/DescriptionPaneModal')));
const PrivacyPolicyModal = React.lazy(() => lazyRetry(() => import('./components/auth/PrivacyPolicyModal')));
const AddSiteModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/AddSiteModal')));
const ViewWarrantyModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/ViewWarrantyModal')));
const CreateCustomerTicketsModal = React.lazy(() => lazyRetry(() => import('./components/customer/CreateCustomerTicketsModal')));
const SiteDetailsDataSelectionModal = React.lazy(() => lazyRetry(() => import('./components/templates/SiteDetailsDataSelectionModal')));
const EditTargetGenerationModal = React.lazy(() => lazyRetry(() => import('./components/site/Modal/EditTargetGenerationModal')));
const AddIntegrationsModal = React.lazy(() => lazyRetry(() => import('./components/integrations/AddIntegrationsModal.jsx')))
// const ConfirmSiteModal = React.lazy(() => lazyRetry(() => import('./components/analytics/NewSites/ConfirmSiteModal.jsx')))
const Configuration = React.lazy(() => lazyRetry(() => import('./components/configuration/ConfigurationPage.jsx')))
const Definitions = React.lazy(() => lazyRetry(() => import('./components/help/Definitions.jsx')))
const Tutorials = React.lazy(() => lazyRetry(() => import('./components/help/Tutorials.jsx')))
const ErrorLogs = React.lazy(() => lazyRetry(() => import('./components/apiErrors/ErrorLogs.jsx')))

// Form
const Forms = React.lazy(()=> import('./components/form/Forms.jsx'))
const CreateForm = React.lazy(() => import('./components/form/CreateForm.jsx'))
const ViewForm = React.lazy(() => import('./components/form/components/technician/ViewForm.jsx'))
const DeleteFormModal = React.lazy(() => import('./components/form/components/DeleteFormModal.jsx'))

const AppLayout = () => {
  const theme = 'white'

  useEffect(() => {
    M.AutoInit()
    // eslint-disable-next-line
  }, [])

  const { hostname } = window.location
  const title = (tag) => {
    const names = {
      'localhost': 'SolYield',
      'solarbhaiops.in': 'Solarbhai',
      'solarbhaiops.com': 'Solarbhai',
      'solyieldops.com': 'SolYield',
      'solyieldops.in': 'SolYield',
    }

    return names[tag] || 'SolYield'
  }

  const logo = (tag) => {
    const logos = {
      'localhost': 'https://solyield-clientfacing.s3.amazonaws.com/solyieldLogo.jpg-1707744665701',
      'solarbhaiops.in': 'https://solyield-clientfacing.s3.amazonaws.com/solyieldLogo.jpg-1707744665701',
      'solarbhaiops.com': 'https://solyield-clientfacing.s3.amazonaws.com/solyieldLogo.jpg-1707744665701',
      'solyieldops.com': 'https://solyield-clientfacing.s3.ap-south-1.amazonaws.com/SolYieldLogoRound.png-1704449604982',
      'solyieldops.in': 'https://solyield-clientfacing.s3.ap-south-1.amazonaws.com/SolYieldLogoRound.png-1704449604982',
    }

    return logos[tag] || 'https://solyield-clientfacing.s3.ap-south-1.amazonaws.com/SolYieldLogoRound.png-1704449604982'
  }

  const [isExtended, setIsExtended] = useState(false);

  const extendNavbar = () => {
    setIsExtended(!isExtended)
  }


  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const determineBgColor = (y) => {
    if (y < 200) return 'dark:tw-bg-[#2A324B]';
    else if (y >= 200 && y < 2000) return 'dark:tw-bg-[#25293E]';
    else if (y >= 2000 && y < 4000) return 'dark:tw-bg-[#1D2230]';
    else return 'dark:tw-bg-[#141619]';
  };

  const bgColor = determineBgColor(scrollY);

  return (
    // <Provider store={store}>
      <div className="app" style={{maxWidth:'100vw'}}>
        <Helmet>
          <title>{title(hostname)}</title>
          <link rel="icon" href={logo(hostname)} />
          <link rel="apple-touch-icon" href={logo(hostname)} />
          <meta name="description" content={'SolYield utilizes Artificial Intelligence (AI) technologies to minimize losses in rooftop solar power plants and improve solar energy generation by up to 15%. Our platform seamlessly connects with solar plants without any upfront cost and delivers proactive recommendations to address over 20 performance issues'} />
        </Helmet>
        {/* <Navbar /> */}

        <ToastContainer theme='colored' />
        <Suspense fallback={<Skeleton />}>
        <DeleteTicketModal />
        <AddCommentModal />
        <DeleteCommentModal />
        <FullViewModal />
        <SendMailModal />
        <SummaryMailModal />
        {/* <ViewSiteModal /> */}
        {/* <EditSiteModal /> */}
        <EditConfigModal />
        <AddConfigModal />
        <DeleteConfigModal />
        <EditMaintenanceModal />
        {/* <ViewNewSiteModal /> */}
        {/* <EditNewSiteModal /> */}
        <AddInverterModal />
        <UpdateInverterModal />
        <ViewInfoModal />
        <EditInfoModal />
        <DeleteInfoModal />
        <UploadImagesModal />
        <ViewTicketModal />
        <ReportOptionsSelectionModal />
        {/* <CommLossEmailPreview /> */}
        <CommLossSchedulingModal />
        <PreviewMaintenanceReportModal />
        <MaintenanceReportPreviewModal />
        <SetSignatureModal />
        <UpdateCustomerDetailsModal />
        <AddUserDetailsModal />
        <EditStringConfigModal />
        <DescriptionPaneModal />
        <PrivacyPolicyModal />
        <AddSiteModal />
        <ViewWarrantyModal />
        <CreateCustomerTicketsModal />
        <SiteDetailsDataSelectionModal />
        <EditTargetGenerationModal />
        <AddIntegrationsModal />
        <DeleteFormModal />
        {/* <ConfirmSiteModal /> */}
        </Suspense>
      <Tooltip id='app-tooltip' style={{ zIndex: 1000 }} />

        <div className={`tw-flex tw-flex-col md:tw-flex-row tw-min-h-screen  ${bgColor} `}>
          <Navbars extendNavbar={extendNavbar} />
          <div className={`tw-flex-1 tw-p-1 ${isExtended ? 'md:tw-pl-64' : 'md:tw-pl-24' } tw-max-w-full tw-py-14 md:tw-pt-0 md:tw-py-8`}>
        <Banner />
            <ScrollToTop />
            <Outlet />
          </div>
        </div>

      </div>
    // </Provider>
  )
}





const appRouter =  (userType) => createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Dashboard /></Suspense></PrivateRoute>
      },
      // {
      //   path: '/tickets',
      //   element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><TicketsPage /></Suspense> </PrivateRoute>
      // },
      {
        path: '/tickets/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Ticket /></Suspense> </PrivateRoute>
      },
      {
        path: '/settings',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Settings /></Suspense> </PrivateRoute>
      },
      {
        path: '/analytics',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Analytics /></Suspense></PrivateRoute>
      },
      {
        path: '/view-site/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><SitePage /></Suspense> </PrivateRoute>
      },
      {
        path: '/site-settings',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><SiteSettings /></Suspense> </PrivateRoute>
      },
      {
        path: '/inverter-settings',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><InverterSettings /></Suspense> </PrivateRoute>
      },
      {
        path: '/fault-detection',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><ModuleFaultDetection /></Suspense> </PrivateRoute>
      },
      {
        path: '/sites',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><SitesSummary /></Suspense> </PrivateRoute>
      },
      {
        path: '/geo-spatial',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><GeoSpatialDashboard /></Suspense> </PrivateRoute>
      },
      // {
      //   path: '/analytics2',
      //   element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><AnalyticsTwo /></Suspense> </PrivateRoute>
      // },
      {
        path: '/view-site2/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><IndividualScreen /></Suspense> </PrivateRoute>
      },
      {
        path: '/templates',
        children: [
          {
            path: '/templates',
            element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><TemplatesPage /></Suspense> </PrivateRoute>,
          },
          {
            path: '/templates/site-page-template',
            element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><SitePageTemplate /></Suspense> </PrivateRoute>
          }, {
            path: '/templates/comm-loss-template',
            element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><CommLossEmail /></Suspense> </PrivateRoute>
          },
          {
            path: '/templates/maintenance-report-template',
            element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><MaintenanceReportTemplate /></Suspense> </PrivateRoute>
          },
        ]
      },

      {
        path: '/site-page-template/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><CustomiseSitePage /></Suspense> </PrivateRoute>
      },

      {
        path: '/update-warranty/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><UpdateWarranty /></Suspense> </PrivateRoute>
      },

      {
        path: '/view-warranty/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><ViewWarranty /></Suspense> </PrivateRoute>
      },

      {
        path: '/add-site',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><AddSite /></Suspense> </PrivateRoute>
      },
      {
        path: '/system-info',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><SystemInfo /></Suspense> </PrivateRoute>
      },
      {
        path: '/service-request',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><ServiceRequest /></Suspense> </PrivateRoute>
      },
      {
        path: '/integrations',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Integrations /></Suspense></PrivateRoute>
      },
      {
        path: '/login',
        element:<Suspense fallback={<Skeleton />}><Login /></Suspense> 
      },
      {
        path: '/signup',
        element:<Suspense fallback={<Skeleton />}><Signup /></Suspense> 
      },
      {
        path: '/forms',
        element: <PrivateRoute><Suspense fallback={<Skeleton />}><Forms /></Suspense></PrivateRoute>
      },
      {
        path: '/create-form',
        element: <PrivateRoute><Suspense fallback={<Skeleton />}><CreateForm /></Suspense></PrivateRoute>
      },
      {
        path: '/create-form/:formId',
        element: <PrivateRoute><Suspense fallback={<Skeleton />}><CreateForm /></Suspense></PrivateRoute>
      },
      {
        path: '/form/:formId/:siteId/:ticketId',
        element: <PrivateRoute><Suspense fallback={<Skeleton />}><ViewForm /></Suspense></PrivateRoute>
      },
      {
        path: '/update-ticket/:id',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><UpdateTicket /></Suspense> </PrivateRoute>
      },
      {
        path: '/create-ticket',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><CreateTicket /></Suspense> </PrivateRoute>
      },
      {
        path:'/create-service-request',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><CreateCustomerTicket /></Suspense> </PrivateRoute>
      },
      {
        path: '/new-sites',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><NewSites /></Suspense> </PrivateRoute>
      },
      {
        path: '/configuration',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Configuration /></Suspense> </PrivateRoute>
      },
      {
        path: '/definitions',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Definitions /></Suspense> </PrivateRoute>
      },
      {
        path: '/tutorials',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><Tutorials /></Suspense> </PrivateRoute>
      },
      {
        path: '/errorLogs',
        element: <PrivateRoute><Suspense fallback={<Skeleton height={'100vh'} />}><ErrorLogs /></Suspense> </PrivateRoute>
      },
    ],
    errorElement: <Error />
  },
])

export default appRouter